import useAsync from '@finst/core/src/scripts/hooks/use-async';
import useMediaQuery from '@finst/core/src/scripts/hooks/use-media-query';
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import unsubscribeAll from '@finst/core/src/scripts/utils/subscription/unsubscribe-all';
import { useLayoutEffect, useRef, useState } from 'preact/compat';
import { darkColorScheme } from '../../../media-queries';
import { defaultAppSettings } from '../../../models/app-settings';
import getLocalLanguage from '../../../services/app-settings/get-local-language';
import getLocalTheme from '../../../services/app-settings/get-local-theme';
import normalizeFeatureAnnouncementDate from '../../../services/app-settings/normalize-feature-announcement-date';
import saveDisplayNameLocally from '../../../services/app-settings/save-display-name-locally';
import saveLanguageLocally from '../../../services/app-settings/save-language-locally';
import saveThemeLocally from '../../../services/app-settings/save-theme-locally';
import useSystemTheme from './use-system-theme';
const mergeContextValueIfChanged = (contextValue, settings)=>{
    let result;
    for(const key in settings){
        const settingName = key;
        const settingValue = settings[settingName];
        if (settingValue !== contextValue[settingName] && Object.prototype.hasOwnProperty.call(settings, key)) {
            // do not mutate the origin source
            result = result || {
                ...contextValue
            };
            result[settingName] = settingValue;
        }
    }
    return result || contextValue;
};
export default function useAppSettingsContextLoader(secureMessageBroker) {
    const secureMessageBrokerRef = useRef(secureMessageBroker);
    const webTheme = useMediaQuery(darkColorScheme) ? 'dark' : 'light';
    const systemTheme = useSystemTheme();
    const { value: localTheme } = useAsync(getLocalTheme, []);
    const [selectedTheme = localTheme || systemTheme || webTheme, setSelectedTheme] = useState();
    const { isLoading: isLocalLanguageLoading, value: localLanguage } = useAsync(getLocalLanguage, []);
    const [selectedLanguage, setSelectedLanguage] = useState();
    const [contextValue, setContextValue] = useState({
        ...defaultAppSettings,
        theme: selectedTheme,
        language: selectedLanguage || defaultAppSettings.language,
        update (settings, options) {
            var _options;
            setContextValue((contextValue)=>mergeContextValueIfChanged(contextValue, settings));
            if (settings.theme) {
                setSelectedTheme(settings.theme);
                saveThemeLocally(settings.theme).catch(localLogger.error);
            }
            if (settings.language) {
                setSelectedLanguage(settings.language);
                saveLanguageLocally(settings.language).catch(localLogger.error);
            }
            if (settings.displayName) {
                saveDisplayNameLocally(settings.displayName).catch(localLogger.error);
            }
            const { current: secureMessageBroker } = secureMessageBrokerRef;
            if (secureMessageBroker && ((_options = options) === null || _options === void 0 ? void 0 : _options.syncViaApi) !== false) {
                secureMessageBroker.send({
                    action: 'appSettingsUpdate',
                    data: settings
                });
            }
        }
    });
    useLayoutEffect(()=>{
        secureMessageBrokerRef.current = secureMessageBroker;
        if (secureMessageBroker) {
            const unsubscribes = [
                secureMessageBroker.onConnect(()=>secureMessageBroker.send({
                        action: 'appSettingsRequest',
                        data: {}
                    })),
                secureMessageBroker.on({
                    action: 'appSettingsData',
                    onError: localLogger.error,
                    onSuccess (settings) {
                        contextValue.update({
                            ...settings,
                            // If the client has not seen the announcements,
                            // set their initial value as tomorrow (see PlatformAnnouncement component)
                            lastSeenNewAssetsAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenNewAssetsAnnouncementAt, // gh-fe-642
                            '2024-11-08T00:00:00.00Z'),
                            lastSeenNewCryptoPaymentAssetsAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenNewCryptoPaymentAssetsAnnouncementAt, '2024-06-26T00:00:00.00Z'),
                            lastSeenBundlesLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenBundlesLaunchAnnouncementAt, '2023-04-17T00:00:00.00Z'),
                            lastSeenBundleCoinDesk20LaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenBundleCoinDesk20LaunchAnnouncementAt, '2023-09-09T00:00:00.00Z'),
                            lastSeenCryptoDepositsLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenCryptoDepositsLaunchAnnouncementAt, '2023-07-20T00:00:00.00Z'),
                            lastSeenCryptoWithdrawalsLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenCryptoWithdrawalsLaunchAnnouncementAt, '2023-09-19T00:00:00.00Z'),
                            lastSeenProofOfReservesAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenProofOfReservesAnnouncementAt, '2023-08-15T00:00:00.00Z'),
                            lastSeenStakingLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenStakingLaunchAnnouncementAt, '2023-12-06T00:00:00.00Z'),
                            lastSeenAutoInvestLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenAutoInvestLaunchAnnouncementAt, '2024-01-09T00:00:00.00Z'),
                            lastSeenAssetLimitOrdersLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenAssetLimitOrdersLaunchAnnouncementAt, '2024-05-08T00:00:00.00Z'),
                            lastSeenAssetStopLossOrdersLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenAssetStopLossOrdersLaunchAnnouncementAt, '2024-08-30T00:00:00.00Z'),
                            lastSeenAssetUpvotingLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenAssetUpvotingLaunchAnnouncementAt, '2024-07-19T00:00:00.00Z'),
                            lastSeenNewReferralProgramAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenNewReferralProgramAnnouncementAt, '2024-07-01T00:00:00.00Z'),
                            lastSeenUpvotedAssetsAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenUpvotedAssetsAnnouncementAt, '2024-08-06T00:00:00.00Z'),
                            lastSeenBalanceBreakdownLaunchAnnouncementAt: normalizeFeatureAnnouncementDate(settings.lastSeenBalanceBreakdownLaunchAnnouncementAt, '2024-11-11T00:00:00.00Z')
                        }, {
                            syncViaApi: false
                        });
                    }
                })
            ];
            return ()=>unsubscribeAll(unsubscribes);
        }
    // eslint-disable-next-line @finst/react-hooks-exhaustive-deps
    }, [
        secureMessageBroker
    ]);
    useLayoutEffect(()=>{
        if (localLanguage && !selectedLanguage) {
            setSelectedLanguage(localLanguage);
            setContextValue((contextValue)=>mergeContextValueIfChanged(contextValue, {
                    language: localLanguage
                }));
        }
    }, [
        localLanguage,
        selectedLanguage
    ]);
    useLayoutEffect(()=>{
        if (selectedLanguage) {
            setContextValue((contextValue)=>mergeContextValueIfChanged(contextValue, {
                    language: selectedLanguage
                }));
        }
    }, [
        selectedLanguage
    ]);
    useLayoutEffect(()=>{
        setContextValue((contextValue)=>mergeContextValueIfChanged(contextValue, {
                theme: selectedTheme
            }));
    }, [
        selectedTheme
    ]);
    return {
        isLoading: isLocalLanguageLoading || localLanguage !== undefined && selectedLanguage === undefined,
        contextValue
    };
}
