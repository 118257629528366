const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const tomorrowISOString = tomorrow.toISOString();
export default function normalizeFeatureAnnouncementDate(lastSeenAt, releasedAt) {
    if (lastSeenAt && // if the release date is newer than the last seen date, it means we want to relaunch the announcement
    new Date(lastSeenAt).getTime() >= new Date(releasedAt).getTime()) {
        return lastSeenAt;
    }
    return tomorrowISOString;
}
