export default function prepareDeviceInfoRequestHeaders(deviceInfo) {
    const { uuid, model, platform, manufacturer } = deviceInfo;
    const headers = {};
    if (uuid) {
        headers['X-DEVICE-ID'] = uuid;
    }
    if (model) {
        headers['X-DEVICE-MODEL'] = model;
    }
    if (platform) {
        headers['X-DEVICE-PLATFORM'] = platform;
    }
    if (manufacturer) {
        headers['X-DEVICE-MANUFACTURER'] = manufacturer;
    }
    return headers;
}
