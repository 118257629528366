import getThemeMetaElement from './get-theme-meta-element';
/**
 * @param {string} backgroundColor – HEX color
 * @param {'light' | 'dark'} contentColor
 * @returns {void}
 */ export default function setStatusBar(backgroundColor, contentColor) {
    const { StatusBar } = window;
    // PWA theme
    const themeMetaElement = getThemeMetaElement();
    if (themeMetaElement) {
        themeMetaElement.content = backgroundColor;
    }
    // Cordova StatusBar plugin
    if (StatusBar) {
        // it may fail if Cordova layer is not fully ready
        try {
            StatusBar.backgroundColorByHexString(backgroundColor);
            if (contentColor === 'light') {
                StatusBar.styleLightContent();
            } else {
                StatusBar.styleDefault();
            }
        } catch  {
        //
        }
    }
}
