import { useCallback, useState } from 'preact/compat';
export default function useToggle(defaultValue = false) {
    const [isOpened, setIsOpened] = useState(defaultValue);
    return {
        isOpened,
        toggle: useCallback(()=>setIsOpened((isOpen)=>!isOpen), []),
        open: useCallback(()=>setIsOpened(true), []),
        close: useCallback(()=>setIsOpened(false), [])
    };
}
