import useAsync from '@finst/core/src/scripts/hooks/use-async';
import { useState } from 'preact/compat';
import { mainModuleId } from '../../../models/i18n';
import getTranslations from '../../../services/i18n/get-translations';
const emptyTranslations = {};
export default function useTranslationsInfoContextLoader(language) {
    const [contextValue, setContextValue] = useState({
        translations: emptyTranslations,
        update (translations) {
            setContextValue((contextValue)=>({
                    ...contextValue,
                    translations
                }));
        }
    });
    useAsync(async ()=>{
        if (language) {
            const translations = await getTranslations({
                language,
                modules: [
                    mainModuleId
                ]
            });
            contextValue.update(translations);
        }
    }, [
        language
    ]);
    return {
        isLoading: contextValue.translations === emptyTranslations,
        contextValue
    };
}
