import { addDocumentKeydownStackableEventListener } from '@finst/core/src/scripts/utils/events/add-stackable-event-listener';
import { useLayoutEffect } from 'preact/compat';
import { focusableElementsQuerySelector } from '../dom-globals';
export default function useMenuKeyboardNavigation(containerRef) {
    useLayoutEffect(()=>{
        if (containerRef) {
            return addDocumentKeydownStackableEventListener((event)=>{
                var _focusableElements_;
                const { current: containerEl } = containerRef;
                if (!containerEl) {
                    return;
                }
                event.preventDefault();
                event.stopPropagation();
                const { key } = event;
                const isNavigatingForward = key === 'ArrowRight' || key === 'ArrowDown';
                const focusableElements = Array.from(containerEl.querySelectorAll(focusableElementsQuerySelector));
                const focusedElementIndex = focusableElements.findIndex((el)=>el === document.activeElement);
                const hasFocusedElement = focusedElementIndex !== -1;
                const elementsCount = focusableElements.length;
                const elementIndex = isNavigatingForward || hasFocusedElement ? focusedElementIndex : elementsCount;
                const elementOffset = isNavigatingForward ? 1 : -1;
                (_focusableElements_ = focusableElements[(elementIndex + elementsCount + elementOffset) % elementsCount]) === null || _focusableElements_ === void 0 ? void 0 : _focusableElements_.focus();
            }, ({ key })=>key === 'ArrowRight' || key === 'ArrowDown' || key === 'ArrowUp' || key === 'ArrowLeft');
        }
    }, [
        containerRef
    ]);
}
