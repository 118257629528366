import { AppError } from '@finst/core/src/scripts/models/app-error';
import getAllAssets from './get-all-assets';
export default async function getAsset(symbol) {
    const allAssets = await getAllAssets();
    const asset = allAssets.find((asset)=>asset.symbol === symbol);
    if (!asset) {
        throw new AppError({
            code: 'NotFound'
        });
    }
    return asset;
}
