import { createPortal, memo, useLayoutEffect, useState } from 'preact/compat';
function createElement() {
    const el = document.createElement('div');
    el.dataset.testid = 'portal';
    return el;
}
const Portal = memo(({ container, children })=>{
    const [el] = useState(createElement);
    useLayoutEffect(()=>{
        (container || document.body).appendChild(el);
        return ()=>el.remove();
    }, [
        container,
        el
    ]);
    return createPortal(children, el);
});
export default Portal;
