import invertSign from './invert-sign';
import isInteger from './is-integer';
import removeLeadingZeros from './remove-leading-zeros';
import removeTrailingZeros from './remove-trailing-zeros';
import subtract from './subtract';
export default function add(augend, addend) {
    if (isInteger(augend, 0)) {
        return addend;
    }
    if (isInteger(addend, 0)) {
        return augend;
    }
    // Check if the signs differ
    if (augend.sign !== addend.sign) {
        return subtract(augend, invertSign(addend));
    }
    const augendDigits = augend.digits.slice(0);
    const addendDigits = addend.digits.slice(0);
    let fractionStart = Math.max(augend.fractionStart, addend.fractionStart);
    // prepend zeros to equalise augend exponent
    for(let i = fractionStart - augend.fractionStart; i > 0; i--){
        augendDigits.unshift(0);
    }
    // prepend zeros to equalise addend exponent
    for(let i = fractionStart - addend.fractionStart; i > 0; i--){
        addendDigits.unshift(0);
    }
    const augendDigitsCount = augendDigits.length;
    const addendDigitsCount = addendDigits.length;
    const digits = [];
    let carry = 0;
    for(let i = Math.max(augendDigitsCount, addendDigitsCount) - 1; i >= 0; i--){
        const sum = carry + (augendDigits[i] || 0) + (addendDigits[i] || 0);
        if (sum >= 10) {
            carry = 1;
            digits[i] = sum - 10;
            if (i === 0) {
                digits.unshift(carry);
                fractionStart++;
            }
        } else {
            carry = 0;
            digits[i] = sum;
        }
    }
    return removeTrailingZeros(removeLeadingZeros({
        sign: augend.sign,
        digits,
        fractionStart
    }));
}
