// !!! IMPORTANT !!! LocalStorage methods trigger an error in Incognito mode in some browsers
export async function getItem(key) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
}
export async function setItem(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}
export async function removeItem(key) {
    localStorage.removeItem(key);
}
