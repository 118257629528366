import { useEffect, useReducer } from 'preact/compat';
import normalizeError from '../services/app-error/normalize-error';
import createCancellablePromise from '../utils/async/create-cancellable-promise';
function reducer(prevState, action) {
    if (action.type === 'SUCCESS') {
        return {
            isLoading: false,
            value: action.payload,
            error: undefined
        };
    }
    /*
     For error state we do not clear value.
     This is useful for cases where you want to show "update" error message on top
     of some existing data
    */ if (action.type === 'ERROR') {
        return {
            isLoading: false,
            value: prevState.value,
            error: action.payload
        };
    }
    return {
        isLoading: true,
        value: prevState.value,
        error: prevState.error
    };
}
/*
    ┌────────────────────┐
    │Init                │                 Signals:
    └────────────────────┘                   : Update
             + ││ -                        + : Success
               │└───────────┐              - : Fail
               │            ▼
               │ ┌────────────────────┐    States:
               │ │Error               │◀┐
               │ └────────────────────┘ │  Init               : {isLoading: true}
               │ ┌────────────────────┐ │
               │ │Fixing Error        │ │  Ok                 : {isLoading: false, value: Value}
               │ └────────────────────┘ │  Error              : {isLoading: false, error: Error}
               │        +   ││  -       │
               │ ┌──────────┘└──────────┘  Updating Ok        : {isLoading: true, value: Value}
  ┌──────────┐ │ │ ┌───────┐               Fixing error       : {isLoading: true, error: Error}
  │          ▼ ▼ ▼ ▼       │
  │ ┌────────────────────┐ │               Ok -> Error        : {isLoading: false, value: Value, error: Error}
  │ │Ok                  │ │
  │ └────────────────────┘ │               Fixing Ok -> Error : {isLoading: true,  value: Value, error: Error}
  │ ┌────────────────────┐ │
  │ │Updating Ok         │ │
  │ └────────────────────┘ │
  │         + ││ -         │
  └───────────┘▼           │
    ┌────────────────────┐ │
  ┌▶│Ok -> Error         │ │
  │ └────────────────────┘ │
  │ ┌────────────────────┐ │
  │ │Fixing Ok -> Error  │ │
  │ └────────────────────┘ │
  │         -  ││ +        │
  └────────────┘└──────────┘
*/ export default function useAsyncWithProgressiveState(asyncFn, deps) {
    const [state, dispatch] = useReducer(reducer, {
        isLoading: true,
        value: undefined,
        error: undefined
    });
    useEffect(()=>{
        const cancellablePromise = createCancellablePromise(asyncFn(state));
        dispatch({
            type: 'LOADING'
        });
        cancellablePromise.promise.then((value)=>dispatch({
                type: 'SUCCESS',
                payload: value
            })).catch((error)=>dispatch({
                type: 'ERROR',
                payload: normalizeError(error)
            }));
        return cancellablePromise.cancel;
    // eslint-disable-next-line @finst/react-hooks-exhaustive-deps
    }, deps);
    return state;
}
