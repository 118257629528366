function createErrorHandler(errorHead, onError) {
    return (error = 'unknown error')=>{
        const errorBody = typeof error === 'string' ? error : `${error.code}:${error.source}:${String(error.exception)}`;
        onError(new Error(`${errorHead} ${errorBody}`));
    };
}
function getKeys() {
    return new Promise((resolve, reject)=>{
        const { NativeStorage } = window;
        const onError = createErrorHandler('[NativeStorage.keys]', reject);
        if (NativeStorage) {
            NativeStorage.keys(resolve, onError);
        } else {
            onError('NATIVE_STORAGE_NOT_SUPPORTED');
        }
    });
}
export function isSupported() {
    return Boolean(window.NativeStorage);
}
export async function getItem(key) {
    const keys = await getKeys();
    // If we request a non-existent key `NativeStorage` will call an onError callback which is not a desired behaviour
    if (!keys.includes(key)) {
        return;
    }
    return new Promise((resolve, reject)=>{
        const onError = createErrorHandler('[NativeStorage.getItem]', reject);
        window.NativeStorage.getItem(key, (json)=>resolve(JSON.parse(json)), onError);
    });
}
export function setItem(key, data) {
    return new Promise((resolve, reject)=>{
        const { NativeStorage } = window;
        const onError = createErrorHandler('[NativeStorage.setItem]', reject);
        if (NativeStorage) {
            NativeStorage.setItem(key, JSON.stringify(data), ()=>resolve(), onError);
        } else {
            onError('NATIVE_STORAGE_NOT_SUPPORTED');
        }
    });
}
export async function removeItem(key) {
    const keys = await getKeys();
    // If we remove a non-existent key `NativeStorage` will call an onError callback which is not a desired behaviour
    if (!keys.includes(key)) {
        return;
    }
    return new Promise((resolve, reject)=>{
        const onError = createErrorHandler('[NativeStorage.remove]', reject);
        window.NativeStorage.remove(key, resolve, onError);
    });
}
