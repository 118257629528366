import { _ as _define_property } from "@swc/helpers/_/_define_property";
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import unsubscribeAll from '@finst/core/src/scripts/utils/subscription/unsubscribe-all';
import propagateScheduledInvestmentsData from '../../services/auto-invest/propagate-scheduled-investments-data';
class AutoInvestBroker {
    on(callback) {
        this.scheduledInvestmentSubscriptions.add(callback);
        if (this.scheduledInvestments) {
            callback(this.scheduledInvestments);
        }
        return ()=>{
            this.scheduledInvestmentSubscriptions.delete(callback);
        };
    }
    close() {
        this.scheduledInvestmentSubscriptions.clear();
        this.scheduledInvestments = undefined;
        unsubscribeAll(this.unsubscribes);
    }
    constructor(props){
        _define_property(this, "scheduledInvestments", void 0);
        _define_property(this, "scheduledInvestmentSubscriptions", new Set());
        _define_property(this, "unsubscribes", void 0);
        _define_property(this, "onScheduledInvestments", (investments)=>{
            propagateScheduledInvestmentsData(investments).then((investments)=>{
                this.scheduledInvestments = investments;
                this.scheduledInvestmentSubscriptions.forEach((callback)=>callback(investments));
            }).catch(localLogger.error);
        });
        const { messageBroker } = props;
        this.unsubscribes = [
            messageBroker.on({
                action: 'scheduledInvestmentsData',
                onSuccess: this.onScheduledInvestments,
                onError: localLogger.error
            }),
            // request a snapshot again when a new connection established (e.g. after reconnect)
            messageBroker.onConnect(()=>{
                messageBroker.send({
                    action: 'scheduledInvestmentsRequest',
                    data: {},
                    onError: localLogger.error
                });
            })
        ];
    }
}
export { AutoInvestBroker as default };
