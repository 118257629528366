export const emptyTotalPortfolio = {
    absoluteDailyPl: 0,
    relativeDailyPl: 0,
    absoluteTotalPl: 0,
    total: 0,
    assets: 0,
    balance: 0,
    availableBalance: 0
};
export const assetPositionValueTypes = [
    'value-pl24h',
    'price-breakEvenPrice',
    'unrealisedPl',
    'positionPl-realisedPositionPl',
    'totalPl'
];
export const bundlePositionValueTypes = [
    'value-pl24h',
    'unrealisedPl',
    'positionPl-realisedPositionPl',
    'totalPl'
];
