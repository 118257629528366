export default function addStackableEventListener(element, eventType) {
    let stack = [];
    const eventHandler = (event)=>{
        var _handler;
        const handler = stack.find(([_, predicate])=>predicate(event));
        (_handler = handler) === null || _handler === void 0 ? void 0 : _handler[0](event);
    };
    element.addEventListener(eventType, eventHandler);
    return (handler, predicate = ()=>true)=>{
        const handlerTuple = [
            handler,
            predicate
        ];
        stack.unshift(handlerTuple);
        return ()=>{
            stack = stack.filter((stackTuple)=>stackTuple !== handlerTuple);
        };
    };
}
// Predefined listeners
export const addDocumentKeydownStackableEventListener = addStackableEventListener(document, 'keydown');
