// extracted by mini-css-extract-plugin
export var accentButton = "iFhb7gCH";
export var button = "x3vUEH29";
export var content = "zfbLZmB3";
export var endIcon = "Rkx112_O";
export var ghostButton = "hHG7HjJ9";
export var loadingButton = "Y33dKqFk";
export var outlinedButton = "Fv6Snc_k";
export var primaryButton = "nu5c5r2S";
export var smallButton = "HEdSjrQ_";
export var spinnerLayout = "G1UA53Da";
export var startIcon = "F9ravxHJ";
export var xsmallButton = "_KzXHYtR";
export var xxsmallButton = "p9heZL1J";