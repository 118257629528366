import { getItem } from '@finst/core/src/scripts/utils/platform/local-storage';
import { localStoreLanguageKey } from '../../models/app-settings';
import { supportedLanguages } from '../../models/i18n';
function getLocaleFromCordovaPlugin() {
    const { globalization } = navigator;
    return new Promise((resolve)=>{
        if (!globalization) {
            return resolve(undefined);
        }
        globalization.getLocaleName((locale)=>resolve(locale.value), ()=>resolve(undefined));
    });
}
/**
 * @returns {Promise<SupportedLanguageCode|undefined>} – A two-letter language code (ISO 639-1)
 */ export default async function getLocalLanguage() {
    var _supportedLanguages_find;
    let locale;
    try {
        locale = await getItem(localStoreLanguageKey);
    } catch  {
    //
    }
    try {
        locale = locale || await getLocaleFromCordovaPlugin();
    } catch  {
    //
    }
    locale = locale || navigator.language;
    const [languageCode] = locale.split('-');
    return (_supportedLanguages_find = supportedLanguages.find((language)=>language.code === languageCode)) === null || _supportedLanguages_find === void 0 ? void 0 : _supportedLanguages_find.code;
}
