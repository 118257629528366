export default function subscribeOnAnimationFrame(callback) {
    let frameRequestId;
    let onFrame = (time)=>{
        callback(time);
        if (onFrame !== undefined) {
            frameRequestId = requestAnimationFrame(onFrame);
        }
    };
    frameRequestId = requestAnimationFrame(onFrame);
    return ()=>{
        cancelAnimationFrame(frameRequestId);
        onFrame = undefined;
    };
}
