import { useCallback, useEffect, useState } from 'preact/compat';
import shallowCompare from '../../../utils/shallow-compare';
const getCurrentLocation = ()=>({
        pathname: window.location.pathname,
        search: window.location.search,
        state: window.history.state
    });
export default function useRouter() {
    const [location, setLocation] = useState(getCurrentLocation);
    const navigate = useCallback((url, { replace, state = null } = {})=>{
        const { title } = document;
        if (replace) {
            window.history.replaceState(state, title, url);
        } else {
            window.history.pushState(state, title, url);
        }
        window.dispatchEvent(new CustomEvent('routerNavigate'));
    }, []);
    const back = useCallback(()=>{
        window.history.back();
    }, []);
    useEffect(()=>{
        const onHistoryChange = ()=>{
            setLocation((location)=>{
                const newLocation = getCurrentLocation();
                return shallowCompare(location, newLocation) ? location : newLocation;
            });
        };
        window.addEventListener('popstate', onHistoryChange);
        window.addEventListener('routerNavigate', onHistoryChange);
        // check if the location changed between the initial useState were called and this useEffect
        onHistoryChange();
        return ()=>{
            window.removeEventListener('popstate', onHistoryChange);
            window.removeEventListener('routerNavigate', onHistoryChange);
        };
    }, []);
    return {
        location,
        navigate,
        back
    };
}
