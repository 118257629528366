import round from './round';
/**
 * @description Returns a difference in percents between 2 values
 * @param {number} currentValue
 * @param {number} baseValue
 * @returns {number}
 */ export default function getRelativeDifference(currentValue, baseValue) {
    if (currentValue === baseValue) {
        return 0;
    }
    const change = baseValue === 0 ? 1 : Math.abs((currentValue - baseValue) / baseValue);
    const signFactor = currentValue - baseValue < 0 ? -1 : 1;
    return signFactor * round(change * 100, 2);
}
