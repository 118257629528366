import { useEffect, useReducer } from 'preact/compat';
import normalizeError from '../services/app-error/normalize-error';
import createCancellablePromise from '../utils/async/create-cancellable-promise';
function reducer(_prevState, action) {
    if (action.type === 'SUCCESS') {
        return {
            isLoading: false,
            value: action.payload,
            error: undefined
        };
    }
    if (action.type === 'ERROR') {
        return {
            isLoading: false,
            value: undefined,
            error: action.payload
        };
    }
    return {
        isLoading: true,
        value: undefined,
        error: undefined
    };
}
export default function useAsync(asyncFn, deps) {
    const [state, dispatch] = useReducer(reducer, {
        isLoading: true,
        value: undefined,
        error: undefined
    });
    useEffect(()=>{
        const cancellablePromise = createCancellablePromise(asyncFn());
        dispatch({
            type: 'LOADING'
        });
        cancellablePromise.promise.then((value)=>dispatch({
                type: 'SUCCESS',
                payload: value
            })).catch((error)=>dispatch({
                type: 'ERROR',
                payload: normalizeError(error)
            }));
        return cancellablePromise.cancel;
    // eslint-disable-next-line @finst/react-hooks-exhaustive-deps
    }, deps);
    return state;
}
