import isInteger from './is-integer';
import removeLeadingZeros from './remove-leading-zeros';
import removeTrailingZeros from './remove-trailing-zeros';
export default function multiply(multiplicand, multiplier) {
    const sign = multiplicand.sign === multiplier.sign ? '+' : '-';
    let multiplicandDigits = multiplicand.digits;
    let multiplierDigits = multiplier.digits;
    // Detect a multiplication by zero – "0 by X" or "X by 0"
    if (isInteger(multiplicand, 0) || isInteger(multiplier, 0)) {
        return {
            sign,
            digits: [
                0
            ],
            fractionStart: 1
        };
    }
    // Detect a multiplication by one – "1 by X"
    if (isInteger(multiplicand, 1)) {
        return {
            sign,
            digits: multiplierDigits.slice(0),
            fractionStart: multiplier.fractionStart
        };
    }
    // Detect a multiplication by one – "X by 1"
    if (isInteger(multiplier, 1)) {
        return {
            sign,
            digits: multiplicandDigits.slice(0),
            fractionStart: multiplicand.fractionStart
        };
    }
    let multiplicandSize = multiplicandDigits.length;
    let multiplierSize = multiplierDigits.length;
    // If array of multiplicand digits has fewer digits than multiplier, swap them.
    if (multiplicandSize < multiplierSize) {
        [multiplicandDigits, multiplierDigits] = [
            multiplierDigits,
            multiplicandDigits
        ];
        [multiplicandSize, multiplierSize] = [
            multiplierSize,
            multiplicandSize
        ];
    }
    const digits = new Array(multiplicandSize + multiplierSize);
    for(let i = multiplierSize - 1; i >= 0; i--){
        const multiplierDigit = multiplierDigits[i];
        let j = multiplicandSize + i;
        let carry = 0;
        while(j > i){
            // Current sum of products at this digit position, plus carry.
            carry = (digits[j] || 0) + multiplierDigit * multiplicandDigits[j - i - 1] + carry;
            digits[j] = carry % 10;
            // eslint-disable-next-line no-bitwise
            carry = carry / 10 | 0;
            j--;
        }
        digits[j] = carry;
    }
    return removeTrailingZeros(removeLeadingZeros({
        sign,
        digits,
        fractionStart: multiplicand.fractionStart + multiplier.fractionStart
    }));
}
