export default function getStakingBundleInfo(bundle, stakingAssetsInfo) {
    let interestDividend = 0;
    let interestDivisor = 0;
    bundle.entries.forEach((entry)=>{
        const assetInfo = stakingAssetsInfo[entry.assetSymbol];
        const volumePercentage = Number(entry.volumePercentage);
        interestDivisor += volumePercentage;
        interestDividend += Number(assetInfo ? assetInfo.interest : 0) * volumePercentage;
    });
    return {
        interest: String(interestDividend / interestDivisor)
    };
}
