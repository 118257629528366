export const mainModuleId = 'bf749b66-f57e-4f3e-8adc-494886a8e3e5';
export const countriesModuleId = 'fe8c336c-9a39-4b9d-bfd3-298871bac684';
export const assetsModuleId = '5b023a33-f754-4568-8596-665c96aeafc7';
export const bundlesModuleId = 'eb75a74e-937b-46bb-a775-eff43ef02456';
export const supportedLanguages = [
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'de',
        name: 'Deutsch'
    },
    {
        code: 'es',
        name: 'Español'
    },
    {
        code: 'fr',
        name: 'Français'
    },
    {
        code: 'nl',
        name: 'Nederlands'
    },
    {
        code: 'pt',
        name: 'Português'
    }
];
export const defaultLanguageCode = 'en';
