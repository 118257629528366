import getAllAssets from '../asset/get-all-assets';
import getAllBundles from '../bundle/get-all-bundles';
export default async function propagateScheduledInvestmentsData(scheduledInvestments) {
    const [allAssets, allBundles] = await Promise.all([
        getAllAssets(),
        getAllBundles()
    ]);
    const allAssetsDictionary = new Map(allAssets.map((asset)=>[
            asset.symbol,
            asset
        ]));
    const allBundlesDictionary = new Map(allBundles.map((bundle)=>[
            bundle.symbol,
            bundle
        ]));
    return scheduledInvestments.map((scheduledInvestment)=>{
        if ('assetSymbol' in scheduledInvestment) {
            return {
                ...scheduledInvestment,
                asset: allAssetsDictionary.get(scheduledInvestment.assetSymbol)
            };
        }
        return {
            ...scheduledInvestment,
            bundle: allBundlesDictionary.get(scheduledInvestment.bundleSymbol)
        };
    });
}
