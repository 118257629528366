import getRelativeDifference from '@finst/core/src/scripts/utils/number/get-relative-difference';
export default function calculateAssetPosition(position, assetPrice, assetRelativeDifference) {
    const { breakEvenPrice } = position;
    // it's acceptable for us to lose a precision in this calculation
    const size = Number(position.size);
    const price = assetPrice !== null && assetPrice !== void 0 ? assetPrice : position.price;
    let absoluteUnrealisedPl;
    let absolutePositionPl;
    let absoluteDailyPl;
    let absoluteTotalPl;
    let value;
    if (size && price) {
        value = size * price;
        absoluteUnrealisedPl = size * (price - breakEvenPrice);
    // if size is 0 we can ignore the unknown price (e.g. for a deslisted asset)
    } else if (!size) {
        value = 0;
        absoluteUnrealisedPl = 0;
    }
    if (value !== undefined) {
        absoluteDailyPl = value + position.dailyBasePl;
        absoluteTotalPl = value + position.basePl;
        absolutePositionPl = value + position.positionBasePl;
    }
    return {
        ...position,
        price,
        value,
        absoluteDailyPl,
        relativeDailyPl: assetRelativeDifference !== null && assetRelativeDifference !== void 0 ? assetRelativeDifference : position.relativeDailyPl,
        absoluteTotalPl,
        absolutePositionPl,
        absoluteUnrealisedPl,
        relativeUnrealisedPl: price === undefined ? undefined : absoluteUnrealisedPl ? getRelativeDifference(price, breakEvenPrice) : 0,
        absoluteRealisedPl: absoluteTotalPl === undefined || absoluteUnrealisedPl === undefined ? undefined : absoluteTotalPl - absoluteUnrealisedPl,
        absoluteRealisedPositionPl: absolutePositionPl === undefined || absoluteUnrealisedPl === undefined ? undefined : absolutePositionPl - absoluteUnrealisedPl
    };
}
