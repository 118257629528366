const placeholderPattern = /{([^}]+)}/g;
const translate = (translations, translationCode, placeholders)=>{
    const translation = translations[translationCode] || translationCode;
    if (!placeholders || translation === translationCode) {
        return translation;
    }
    return translation.replace(placeholderPattern, (_, placeholderName)=>{
        const placeholderValue = placeholders[placeholderName];
        return placeholderValue || placeholderValue === 0 ? String(placeholderValue) : '';
    });
};
export default translate;
