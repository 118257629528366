const convertToAnswerValue = (value)=>value ? 'yes' : 'no';
export default function openInAppBrowser(options) {
    var _window_cordova;
    const InAppBrowser = (_window_cordova = window.cordova) === null || _window_cordova === void 0 ? void 0 : _window_cordova.InAppBrowser;
    if (!InAppBrowser) {
        return;
    }
    // show location by default
    const { location = true, toolbar = true, target, url } = options;
    const browserOptions = [
        `location=${convertToAnswerValue(location)}`,
        `toolbar=${convertToAnswerValue(toolbar)}`
    ];
    // https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-inappbrowser/
    return InAppBrowser.open(url, target, browserOptions.join(','));
}
