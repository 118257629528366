// extracted by mini-css-extract-plugin
export var backdrop = "ObyuZ0oA";
export var button = "fiTmLO_w";
export var centeredHeaderTitle = "bi0DlxWq";
export var content = "LsbCv2vE";
export var contentImage = "yAEBqF40";
export var contentParagraph = "mjLPO4ov";
export var contentTitle = "HBS0gL4l";
export var contentTitleIcon = "luan0K2U";
export var endContentFooter = "NF4fZHcn";
export var footer = "RWiaKVYI";
export var fullScreenStretchHeight = "krcim_vN";
export var fullScreenWindow = "su0cFhMq";
export var fullWidthButton = "wpkLl_NX";
export var header = "t9kPFDTo";
export var headerContent = "NFOUv4H0";
export var headerTitle = "LhwwfOMw";
export var justifiedContentFooter = "pLm0kjkb";
export var mediumWindow = "K8PR1Ewq";
export var openingModalWindow = "nhlDNCwe";
export var smallWindow = "FwKYc1l3";
export var window = "r4sH8nmq";
export var xlargeWindow = "_ceuRA9Y";