export default function removeTrailingZeros(state) {
    const { digits, fractionStart } = state;
    let { length: digitsCount } = digits;
    for(let i = digitsCount - 1; i >= fractionStart; i--){
        if (digits[i] === 0) {
            digitsCount--;
        } else {
            break;
        }
    }
    return {
        sign: state.sign,
        fractionStart: Math.min(fractionStart, digitsCount),
        digits: digits.slice(0, digitsCount)
    };
}
