export default function calculateTotalPortfolio(update) {
    const { availableBalance, balance, assets, absoluteTotalPl, absoluteDailyPl } = update;
    const total = assets + availableBalance;
    return {
        total,
        assets,
        balance,
        availableBalance,
        absoluteTotalPl,
        absoluteDailyPl,
        relativeDailyPl: absoluteDailyPl && total && absoluteDailyPl * 100 / (total - absoluteDailyPl)
    };
}
