import { useEffect, useRef } from 'preact/compat';
/**
 * @description IMPORTANT! Use carefully! You might catch bugs with this component!
 * Let assume that you have such component tree structure
 *                       +--------------------+
 *                       | title: Root title  |
 *                       +---------+----------+
 *                                 |
 *                           +-----+-------+
 *                           |             |
 *        +------------------v--+       +--v------------------+
 *        | title: {A1} title   |       | title: {B1} title   |
 *        +------------------+--+       +---------------------+
 *                           |
 *           +---------------v---+
 *           | title: {A2} title |
 *           +-------------------+
 *
 *  1. Conflict situation. What title should be displayed?
 *  2. Let assume that we resolve this conflict and chose to render {A2} title
 *  3. If you try to set {B1} component title (asynchronous) you will get
 *    Error:
 *        Expect: document.title = {A2} title
 *        Actual: document.title = {B1} title
 *
 *  Also, there are few corner cases with add/remove/update nodes (Components) in the tree view.
 *
 *  For now, we do not provide any conflict resolving algorithm.
 *  That is why you should care about all corner cases by yourself.
 * @param {string} title
 * @returns {void}
 */ export default function useDocumentTitle(title) {
    const initialTitleRef = useRef(document.title);
    useEffect(()=>{
        const { current: initialTitle } = initialTitleRef;
        document.title = title;
        return ()=>{
            document.title = initialTitle;
        };
    }, [
        title
    ]);
}
