import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import { getItem, removeItem } from '@finst/core/src/scripts/utils/platform/native-storage';
import { localAccountsStoreKey } from '../../models/account';
const obsoleteLocalAccountStoreKey = 'account';
export default async function getLocalAccounts() {
    const [obsoleteLocalAccount, localAccounts = []] = await Promise.all([
        getItem(obsoleteLocalAccountStoreKey),
        getItem(localAccountsStoreKey)
    ]);
    if (obsoleteLocalAccount) {
        // delete obsolete local account, ignore any error
        await removeItem(obsoleteLocalAccountStoreKey).catch(localLogger.error);
        return [
            obsoleteLocalAccount,
            ...localAccounts.filter((acc)=>acc.email !== obsoleteLocalAccount.email)
        ];
    }
    return localAccounts;
}
