import request from '@finst/core/src/scripts/services/api/request';
import getStakingAssetsInfo from '../staking/get-staking-assets-info';
import getStakingBundleInfo from '../staking/get-staking-bundle-info';
let lastResult;
export default async function getAllBundles() {
    if (!lastResult) {
        const [bundleResponses, stakingAssetsInfoResponse] = await Promise.all([
            request({
                url: '/api/bundles/all'
            }),
            getStakingAssetsInfo()
        ]);
        const stakingAssetsInfo = Object.fromEntries(stakingAssetsInfoResponse.map((item)=>[
                item.asset.symbol,
                item
            ]));
        // keep ALL bundles in memory on FE
        lastResult = bundleResponses.map((bundle)=>({
                ...bundle,
                stakingInterest: getStakingBundleInfo(bundle, stakingAssetsInfo).interest
            }));
    }
    return lastResult;
}
