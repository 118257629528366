export default function getOriginalEventHandlers() {
    if (window.cordova) {
        return window.cordova.getOriginalHandlers();
    }
    return {
        document: {
            addEventListener: document.addEventListener,
            removeEventListener: document.removeEventListener
        },
        window: {
            addEventListener: window.addEventListener,
            removeEventListener: window.removeEventListener
        }
    };
}
