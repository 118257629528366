import useRouter from '@finst/core/src/scripts/components/router/hooks/use-router';
import isAppError from '@finst/core/src/scripts/services/app-error/is-app-error';
import { useLayoutEffect } from 'preact/compat';
import useIdentity from './use-identity';
export default function useAuthError(error) {
    const { navigate } = useRouter();
    const { update: updateIdentity, account } = useIdentity();
    useLayoutEffect(()=>{
        if (isAppError(error) && error.code === 'Unauthorized') {
            updateIdentity({
                state: 'UNAUTHORIZED',
                account
            });
            navigate('/identity/login', {
                replace: true
            });
        }
    }, [
        error,
        account,
        updateIdentity,
        navigate
    ]);
}
