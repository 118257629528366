const { hasOwnProperty } = Object.prototype;
export default function shallowCompare(first, second) {
    if (Object.is(first, second)) {
        return true;
    }
    if (typeof first !== 'object' || first === null || typeof second !== 'object' || second === null) {
        return false;
    }
    const keysOfFirst = Object.keys(first);
    const keysOfSecond = Object.keys(second);
    const keysOfFirstCount = keysOfFirst.length;
    if (keysOfFirstCount !== keysOfSecond.length) {
        return false;
    }
    for(let i = 0; i < keysOfFirstCount; i++){
        const propOfFirst = keysOfFirst[i];
        if (!hasOwnProperty.call(second, propOfFirst) || !Object.is(first[propOfFirst], second[keysOfSecond[i]])) {
            return false;
        }
    }
    return true;
}
