import { _ as _define_property } from "@swc/helpers/_/_define_property";
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import unsubscribeAll from '@finst/core/src/scripts/utils/subscription/unsubscribe-all';
class WatchlistBroker {
    add(assetSymbols) {
        this.messageBroker.send({
            action: 'watchlistAdd',
            data: {
                assetSymbols
            },
            onError: localLogger.error
        });
    }
    remove(assetSymbols) {
        this.messageBroker.send({
            action: 'watchlistRemove',
            data: {
                assetSymbols
            },
            onError: localLogger.error
        });
    }
    on(callback) {
        this.subscriptions.add(callback);
        if (this.assetSymbols) {
            callback(this.assetSymbols);
        }
        return ()=>{
            this.subscriptions.delete(callback);
        };
    }
    close() {
        unsubscribeAll(this.unsubscribes);
        this.subscriptions.clear();
    }
    constructor(props){
        _define_property(this, "messageBroker", void 0);
        _define_property(this, "assetSymbols", void 0);
        _define_property(this, "subscriptions", new Set());
        _define_property(this, "unsubscribes", void 0);
        _define_property(this, "onSnapshot", ({ assetSymbols })=>{
            this.assetSymbols = assetSymbols;
            this.subscriptions.forEach((callback)=>callback(assetSymbols));
        });
        const { messageBroker } = props;
        this.messageBroker = messageBroker;
        this.unsubscribes = [
            messageBroker.on({
                action: 'watchlistData',
                onSuccess: this.onSnapshot,
                onError: localLogger.error
            }),
            // request a snapshot again when a new connection established (e.g. after reconnect)
            messageBroker.onConnect(()=>messageBroker.send({
                    action: 'watchlistRequest',
                    data: {},
                    onError: localLogger.error
                }))
        ];
    }
}
export { WatchlistBroker as default };
