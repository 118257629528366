import { _ as _define_property } from "@swc/helpers/_/_define_property";
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import { AppError } from '@finst/core/src/scripts/models/app-error';
import generateId from '@finst/core/src/scripts/utils/generate-id';
import getOriginalEventHandlers from '@finst/core/src/scripts/utils/platform/get-original-event-handlers';
const windowDeviceStatusEvents = [
    'online',
    'pageshow',
    'visibilitychange',
    'resume'
];
const logErrorLocally = (...args)=>localLogger.error('[message-broker]', ...args);
const isErrorMessage = (message)=>{
    return message.meta.status !== 200;
};
class MessageBroker {
    addDeviceStatusListeners() {
        const { addEventListener } = getOriginalEventHandlers().window;
        windowDeviceStatusEvents.forEach((eventName)=>{
            addEventListener.call(window, eventName, this.onDeviceStatusChange, false);
        });
    }
    removeDeviceStatusListeners() {
        const { removeEventListener } = getOriginalEventHandlers().window;
        windowDeviceStatusEvents.forEach((eventName)=>{
            removeEventListener.call(window, eventName, this.onDeviceStatusChange, false);
        });
    }
    addWsEventListeners() {
        this.ws.addEventListener('open', this.onOpenEvent);
        this.ws.addEventListener('message', this.onMessageEvent);
        this.ws.addEventListener('error', this.onErrorEvent);
        this.ws.addEventListener('close', this.onCloseEvent);
    }
    removeWsEventListeners() {
        this.ws.removeEventListener('open', this.onOpenEvent);
        this.ws.removeEventListener('message', this.onMessageEvent);
        this.ws.removeEventListener('error', this.onErrorEvent);
        this.ws.removeEventListener('close', this.onCloseEvent);
    }
    stopHeartbeat() {
        self.clearInterval(this.heartbeatTimerId);
    }
    startHeartbeat() {
        let lastHeartbeatSentAt;
        this.heartbeatTimerId = self.setInterval(()=>{
            const { lastMessageReceivedAt } = this;
            // Close the connection and reconnect if the last message is older than the previous heartbeat
            if (lastHeartbeatSentAt && (!lastMessageReceivedAt || new Date(lastHeartbeatSentAt).getTime() > new Date(lastMessageReceivedAt).getTime())) {
                this.removeWsEventListeners();
                this.ws.close();
                this.stopHeartbeat();
                this.resetReconnect();
                this.reconnect();
                return;
            }
            const messageId = generateId();
            lastHeartbeatSentAt = new Date().toISOString();
            this.sendMessage({
                meta: {
                    action: 'heartbeat',
                    messageId,
                    correlationId: messageId,
                    sourceId: messageId
                },
                data: {}
            });
        }, 15000);
    }
    resetReconnect() {
        self.clearTimeout(this.reconnectTimerId);
        this.reconnectAttempts = 0;
    }
    reconnect() {
        if (this.reconnectAttempts >= this.maxReconnectAttempts) {
            return;
        }
        this.removeWsEventListeners();
        self.clearTimeout(this.reconnectTimerId);
        this.reconnectTimerId = self.setTimeout(()=>{
            this.lastMessageReceivedAt = undefined;
            this.reconnectAttempts++;
            this.ws = new WebSocket(this.ws.url);
            this.addWsEventListeners();
        }, 1000);
    }
    sendMessage(message) {
        const data = JSON.stringify(message);
        if (this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(data);
        } else {
            this.sendingQueue.push(data);
        }
    }
    onConnect(callback) {
        const { connectListeners } = this;
        if (this.ws.readyState === WebSocket.OPEN) {
            callback();
        }
        connectListeners.add(callback);
        return ()=>{
            connectListeners.delete(callback);
        };
    }
    onAuthError(callback) {
        const { authErrorListeners } = this;
        authErrorListeners.add(callback);
        return ()=>{
            authErrorListeners.delete(callback);
        };
    }
    send(params) {
        const messageId = generateId();
        const { action, correlationId = messageId, sourceId, data, onConfirm, onError } = params;
        const unsubscribe = this.on({
            // Do not allow subscribing on `ACK` action from "outside",
            // but only within MessageBroker as it's a protocol "low-level" action
            action: 'ACK',
            correlationId,
            sourceId: messageId,
            onError (error) {
                var _onError;
                logErrorLocally(`ACK for action "${action}" failed`, error);
                // make sure that ACK triggers only once
                unsubscribe();
                (_onError = onError) === null || _onError === void 0 ? void 0 : _onError(error);
            },
            onSuccess () {
                var _onConfirm;
                // make sure that ACK triggers only once
                unsubscribe();
                (_onConfirm = onConfirm) === null || _onConfirm === void 0 ? void 0 : _onConfirm();
            }
        });
        this.sendMessage({
            meta: {
                messageId,
                action,
                correlationId,
                sourceId
            },
            data: data || {}
        });
        return {
            messageId,
            correlationId,
            unsubscribe
        };
    }
    on(params) {
        const { action, correlationId, sourceId, onError, onSuccess } = params;
        const subscription = {
            correlationId,
            sourceId,
            onMessage (message) {
                if (isErrorMessage(message)) {
                    onError(new AppError({
                        errors: message.errors
                    }));
                } else {
                    onSuccess(message.data, message.meta);
                }
            }
        };
        const { messageSubscriptions } = this;
        const subscriptions = messageSubscriptions.get(action) || new Set();
        subscriptions.add(subscription);
        messageSubscriptions.set(action, subscriptions);
        return ()=>{
            const wasDeleted = subscriptions.delete(subscription);
            // it was the last subscription
            if (wasDeleted && !subscriptions.size) {
                messageSubscriptions.delete(action);
            }
        };
    }
    close() {
        // cleanup
        this.sendingQueue.length = 0;
        this.messageSubscriptions.clear();
        this.authErrorListeners.clear();
        this.connectListeners.clear();
        this.stopHeartbeat();
        this.resetReconnect();
        this.removeWsEventListeners();
        this.removeDeviceStatusListeners();
        this.ws.close();
    }
    constructor(init){
        _define_property(this, "ws", void 0);
        _define_property(this, "lastMessageReceivedAt", void 0);
        _define_property(this, "heartbeatTimerId", void 0);
        _define_property(this, "reconnectTimerId", void 0);
        _define_property(this, "reconnectAttempts", 0);
        _define_property(this, "maxReconnectAttempts", 6);
        _define_property(this, "connectListeners", new Set());
        _define_property(this, "authErrorListeners", new Set());
        _define_property(this, "messageSubscriptions", new Map());
        _define_property(this, "sendingQueue", []);
        _define_property(this, "onDeviceStatusChange", ()=>{
            const { readyState } = this.ws;
            if (document.visibilityState !== 'hidden' && readyState !== WebSocket.CONNECTING && readyState !== WebSocket.OPEN) {
                this.stopHeartbeat();
                this.resetReconnect();
                this.reconnect();
            }
        });
        _define_property(this, "onOpenEvent", ()=>{
            this.reconnectAttempts = 0;
            this.sendingQueue.forEach((data)=>this.ws.send(data));
            // cleanup
            this.sendingQueue.length = 0;
            this.connectListeners.forEach((callback)=>callback());
            this.startHeartbeat();
        });
        _define_property(this, "onMessageEvent", (event)=>{
            var _subscriptions;
            let message;
            let authErrorInfo;
            this.lastMessageReceivedAt = new Date().toISOString();
            try {
                message = JSON.parse(event.data);
            } catch (error) {
                logErrorLocally('Message parsing error:', {
                    data: event.data,
                    error
                });
                return;
            }
            if (!message || !message.meta /* || !(isErrorMessage(message) ? message.errors : message.data) */ ) {
                logErrorLocally('Invalid message structure:', message);
                return;
            }
            // TODO: remove after BE fix (see issue #1) and uncomment check in the "IF" statement above
            if (isErrorMessage(message)) {
                message.errors = message.errors || [];
                authErrorInfo = message.errors.find((error)=>error.code === 'Unauthorized');
            } else {
                message.data = message.data || {};
            }
            const { action, correlationId, sourceId } = message.meta;
            const subscriptions = this.messageSubscriptions.get(action);
            let foundSubscription = false;
            (_subscriptions = subscriptions) === null || _subscriptions === void 0 ? void 0 : _subscriptions.forEach((subscription)=>{
                if ((!subscription.correlationId || subscription.correlationId === correlationId) && (!subscription.sourceId || subscription.sourceId === sourceId)) {
                    foundSubscription = true;
                    subscription.onMessage(message);
                }
            });
            if (!foundSubscription && action === 'NACK' && authErrorInfo) {
                const authError = new AppError(authErrorInfo);
                this.authErrorListeners.forEach((callback)=>callback(authError));
            }
        });
        _define_property(this, "onErrorEvent", (event)=>logErrorLocally('WebSocket error:', event));
        _define_property(this, "onCloseEvent", (event)=>{
            logErrorLocally('WebSocket closed:', event);
            if (!event.wasClean) {
                this.stopHeartbeat();
                this.reconnect();
            }
        });
        this.ws = new WebSocket(init.url);
        this.addWsEventListeners();
        this.addDeviceStatusListeners();
    }
}
export { MessageBroker as default };
