import { useCallback, useEffect, useReducer, useRef } from 'preact/compat';
import normalizeError from '../services/app-error/normalize-error';
import createCancellablePromise from '../utils/async/create-cancellable-promise';
function reducer(_prevState, action) {
    if (action.type === 'SUCCESS') {
        return {
            isLoading: false,
            value: action.payload,
            error: undefined
        };
    }
    if (action.type === 'ERROR') {
        return {
            isLoading: false,
            value: undefined,
            error: action.payload
        };
    }
    return {
        isLoading: true,
        value: undefined,
        error: undefined
    };
}
export default function useAsyncFn(asyncFn) {
    const promiseRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, {
        isLoading: false,
        value: undefined,
        error: undefined
    });
    const act = useCallback((...args)=>{
        var _promiseRef_current;
        (_promiseRef_current = promiseRef.current) === null || _promiseRef_current === void 0 ? void 0 : _promiseRef_current.cancel();
        promiseRef.current = createCancellablePromise(asyncFn(...args));
        dispatch({
            type: 'LOADING'
        });
        promiseRef.current.promise.then((value)=>dispatch({
                type: 'SUCCESS',
                payload: value
            })).catch((error)=>dispatch({
                type: 'ERROR',
                payload: normalizeError(error)
            }));
    }, [
        asyncFn
    ]);
    const cancel = useCallback((value)=>{
        var _promiseRef_current;
        (_promiseRef_current = promiseRef.current) === null || _promiseRef_current === void 0 ? void 0 : _promiseRef_current.cancel();
        promiseRef.current = null;
        dispatch({
            type: 'SUCCESS',
            payload: value
        });
    }, []);
    useEffect(()=>{
        // an unmount cleanup
        return ()=>{
            var _promiseRef_current;
            (_promiseRef_current = promiseRef.current) === null || _promiseRef_current === void 0 ? void 0 : _promiseRef_current.cancel();
            promiseRef.current = null;
        };
    }, []);
    return {
        ...state,
        act,
        cancel
    };
}
