/**
 * @see https://github.com/ionic-team/ionic-plugin-deeplinks
 * @returns {void}
 */ import { customUrlScheme } from '../../models/app-deep-link';
const callbacks = new Set();
let isGlobalListenerAttached = false;
export default function subscribeOnAppDeepLink(callback) {
    const { IonicDeeplink } = window;
    callbacks.add(callback);
    if (!isGlobalListenerAttached && IonicDeeplink) {
        // `IonicDeeplink.onDeepLink` can have ONLY 1 global handler
        isGlobalListenerAttached = true;
        IonicDeeplink.onDeepLink((data)=>{
            const origin = `${window.location.origin}/`;
            const normalizedData = {
                ...data,
                url: data.url// support custom URL scheme as well
                .replace(customUrlScheme, origin)// redirect to custom url scheme might not work (conflict with Deep Links) on Android
                .replace(encodeURIComponent(customUrlScheme), encodeURIComponent(origin))
            };
            callbacks.forEach((callback)=>callback(normalizedData));
        });
    }
    return ()=>{
        callbacks.delete(callback);
    };
}
