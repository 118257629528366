import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import { useEffect, useState } from 'preact/compat';
export default function useSystemTheme() {
    var _window_cordova_plugins, _window_cordova;
    const SystemAppearance = (_window_cordova = window.cordova) === null || _window_cordova === void 0 ? void 0 : (_window_cordova_plugins = _window_cordova.plugins) === null || _window_cordova_plugins === void 0 ? void 0 : _window_cordova_plugins.SystemAppearance;
    const [theme, setTheme] = useState();
    useEffect(()=>{
        if (!SystemAppearance) {
            return;
        }
        const onInfo = (appearance)=>setTheme(appearance.theme);
        SystemAppearance.getInfo().then(onInfo).catch(localLogger.error);
        SystemAppearance.addEventListener('change', onInfo, false);
        return ()=>SystemAppearance.removeEventListener('change', onInfo, false);
    }, [
        SystemAppearance
    ]);
    return theme;
}
