const stackLineParsers = [
    {
        // Blink based browsers: Chrome, Brave, new Opera, new Edge
        pattern: // eslint-disable-next-line max-len
        /^\s*at (?:(.*?) ?\()?((?:file|https?|blob|chrome-extension|native|eval|webpack|<anonymous>|[a-z]:|\/).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i,
        exec (line) {
            const parts = this.pattern.exec(line);
            if (parts) {
                const [, functionName, filePath, lineNumber] = parts;
                return {
                    filePath,
                    functionName,
                    lineNumber
                };
            }
        }
    },
    {
        pattern: // eslint-disable-next-line max-len
        /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|moz-extension).*?:\/.*?|\[native code]|[^@]*bundle)\s+line\s+(\d+)\s*$/i,
        exec (line) {
            const parts = this.pattern.exec(line);
            if (parts) {
                const [, functionName, , filePath, lineNumber] = parts;
                return {
                    filePath,
                    functionName,
                    lineNumber
                };
            }
        }
    },
    {
        // match Blob URLs like `blob:http://url/path/with-some-uuid`, matched by `blob.*?:\/` as well
        pattern: // eslint-disable-next-line max-len
        /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|moz-extension).*?:\/.*?|\[native code]|[^@]*bundle)(?::(\d+))?(?::(\d+))?\s*$/i,
        exec (line) {
            const parts = this.pattern.exec(line);
            if (parts) {
                const [, functionName, , filePath, lineNumber] = parts;
                return {
                    filePath,
                    functionName,
                    lineNumber
                };
            }
        }
    }
];
// Used to additionally parse URL/line/column from eval frames
const geckoEval = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i;
const blinkEval = /\((\S*)(?::(\d+))(?::(\d+))\)/;
export default function getStackInfo(stack) {
    const lines = stack.trim().split('\n');
    const defaultFilePath = location.href;
    for (const line of lines){
        let parserResult;
        for (const parser of stackLineParsers){
            var _parserResult;
            parserResult = parser.exec(line);
            if ((_parserResult = parserResult) === null || _parserResult === void 0 ? void 0 : _parserResult.filePath) {
                let { filePath, lineNumber } = parserResult;
                let evalParts = null;
                if (filePath.startsWith('eval')) {
                    evalParts = blinkEval.exec(filePath);
                } else if (filePath.includes(' > eval')) {
                    evalParts = geckoEval.exec(filePath);
                }
                if (evalParts) {
                    // use top-most line number
                    [, filePath, lineNumber] = evalParts;
                }
                return {
                    filePath,
                    functionName: parserResult.functionName || undefined,
                    lineNumber: lineNumber ? Number(lineNumber) : undefined
                };
            }
        }
    }
    return {
        filePath: defaultFilePath
    };
}
