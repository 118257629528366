import { _ as _define_property } from "@swc/helpers/_/_define_property";
let _Symbol_toStringTag = Symbol.toStringTag;
/**
 * @description It's a class for in-app errors, created in services or based on API response
 * @class
 */ export class AppError {
    get [_Symbol_toStringTag]() {
        return 'AppError';
    }
    toString() {
        return JSON.stringify(this);
    }
    constructor({ code, field, cause, message = '', errors = [], extra = {} } = {}){
        _define_property(this, "name", 'AppError');
        _define_property(this, "message", '');
        _define_property(this, "cause", void 0);
        _define_property(this, "code", void 0);
        _define_property(this, "field", void 0);
        _define_property(this, "errors", void 0);
        _define_property(this, "extra", void 0);
        this.cause = cause;
        this.extra = extra;
        const firstError = errors[0];
        // parse a single error
        if (errors.length === 1 && firstError.code !== 'ValidationError') {
            Object.assign(this, firstError);
            this.code = firstError.code || code;
            this.message = firstError.message || message;
            this.errors = [];
        } else {
            this.code = code;
            this.message = message;
            this.field = field;
            this.errors = errors.map((error)=>new AppError(error));
        }
    }
}
