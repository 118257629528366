import { _ as _define_property } from "@swc/helpers/_/_define_property";
import add from './add';
import isInteger from './is-integer';
import multiply from './multiply';
import removeLeadingZeros from './remove-leading-zeros';
import removeTrailingZeros from './remove-trailing-zeros';
import round from './round';
import subtract from './subtract';
const presets = {
    amount: {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true
    },
    maxAmount: {
        minimumFractionDigits: 2,
        useGrouping: true
    },
    percent: {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
        signDisplay: 'exceptZero'
    }
};
let _Symbol_toStringTag = Symbol.toStringTag;
class Numeral {
    format(options) {
        const presetOptions = options.preset && presets[options.preset] || {};
        var _presetOptions_useGrouping;
        /* eslint-disable prefer-const */ let { thousandSeparator, decimalSeparator, roundingFractionDigits = presetOptions.roundingFractionDigits, useGrouping = (_presetOptions_useGrouping = presetOptions.useGrouping) !== null && _presetOptions_useGrouping !== void 0 ? _presetOptions_useGrouping : true, signDisplay = presetOptions.signDisplay || 'negative', minimumFractionDigits = presetOptions.minimumFractionDigits || 0, maximumFractionDigits = presetOptions.maximumFractionDigits } = options;
        /* eslint-enable */ if (minimumFractionDigits > Number(maximumFractionDigits)) {
            maximumFractionDigits = minimumFractionDigits;
        }
        const { sign, digits, fractionStart } = roundingFractionDigits === undefined ? this.state : round(this.state, roundingFractionDigits);
        let integerPart = '';
        let fractionPart = '';
        let fractionSize = 0;
        let containsNonZeroDigits = false;
        for(let i = fractionStart - 1; i >= 0; i--){
            const digit = digits[i];
            containsNonZeroDigits = containsNonZeroDigits || digit !== 0;
            integerPart = digit + integerPart;
            // Checks:
            // 1. Grouping should be activated
            // 2. It should not be the last iteration
            if (useGrouping && i !== 0 && (fractionStart - i) % 3 === 0) {
                integerPart = thousandSeparator + integerPart;
            }
        }
        const fractionEnd = maximumFractionDigits === undefined ? digits.length : Math.min(fractionStart + maximumFractionDigits, digits.length);
        for(let i = fractionStart; i < fractionEnd; i++){
            const digit = digits[i];
            containsNonZeroDigits = containsNonZeroDigits || digit !== 0;
            fractionPart += digit;
            fractionSize++;
        }
        // add trailing zeros to meet length requirement
        while(fractionSize < minimumFractionDigits){
            fractionPart += '0';
            fractionSize++;
        }
        for(let i = fractionSize - 1; i >= minimumFractionDigits; i--){
            const char = fractionPart[i];
            if (char === '0') {
                // decrease size to remove trailing zero
                fractionSize--;
            } else {
                break;
            }
        }
        fractionPart = fractionPart.slice(0, fractionSize);
        const unsignedValue = fractionPart ? `${integerPart}${decimalSeparator}${fractionPart}` : integerPart;
        if (containsNonZeroDigits && (signDisplay === 'negative' && sign === '-' || signDisplay === 'exceptZero')) {
            return `${sign}${unsignedValue}`;
        }
        return unsignedValue;
    }
    get [_Symbol_toStringTag]() {
        return 'Numeral';
    }
    round(roundingFractionDigits, roundingMode) {
        return new Numeral(round(this.state, roundingFractionDigits, roundingMode));
    }
    add(addend) {
        return new Numeral(add(this.state, addend.state));
    }
    subtract(subtrahend) {
        return new Numeral(subtract(this.state, subtrahend.state));
    }
    multiply(multiplier) {
        return new Numeral(multiply(this.state, multiplier.state));
    }
    isZero() {
        return isInteger(this.state, 0);
    }
    toNumber() {
        const value = Number(this.toString());
        return value === 0 && this.state.sign === '-' ? -0 : value;
    }
    toString() {
        const { sign, digits, fractionStart } = this.state;
        const integerPart = digits.slice(0, fractionStart).join('');
        const fractionPart = digits.slice(fractionStart).join('');
        const unsignedValue = fractionPart ? `${integerPart}.${fractionPart}` : integerPart;
        return `${sign === '-' && unsignedValue !== '0' ? sign : ''}${unsignedValue}`;
    }
    toJSON() {
        return this.toString();
    }
    valueOf() {
        return this.toString();
    }
    constructor(value){
        _define_property(this, "state", void 0);
        if (typeof value === 'object' && 'digits' in value) {
            this.state = {
                digits: value.digits.slice(0),
                fractionStart: value.fractionStart,
                sign: value.sign
            };
            return this;
        }
        const valueAsStr = value ? String(value) : '0';
        // eslint-disable-next-line prefer-destructuring
        const length = valueAsStr.length;
        let digits = [];
        let sign = '+';
        let fractionStart;
        let exponent;
        for(let i = 0; i < length; i++){
            const char = valueAsStr[i];
            if (i === 0 && (char === '-' || char === '+')) {
                sign = char;
            } else if (char === '.' || char === ',') {
                fractionStart = digits.length;
            } else if (char >= '0' && char <= '9') {
                digits.push(Number(char));
            } else if ((char === 'e' || char === 'E') && digits.length > 0) {
                // scientific notation
                exponent = parseInt(valueAsStr.slice(i + 1), 10);
                if (Number.isNaN(exponent)) {
                    exponent = undefined;
                } else {
                    break;
                }
            } else {
                // invalid numeric input
                exponent = undefined;
                fractionStart = undefined;
                digits = [
                    0
                ];
                break;
            }
        }
        if (exponent !== undefined) {
            fractionStart = (fractionStart !== null && fractionStart !== void 0 ? fractionStart : digits.length) + exponent;
        }
        if (fractionStart !== undefined) {
            // add missing digits at the start
            for(let i = fractionStart; i <= 0; i++){
                digits.unshift(0);
                fractionStart++;
            }
            // add missing digits at the end
            for(let i = digits.length; i < fractionStart; i++){
                digits.push(0);
            }
        }
        this.state = removeTrailingZeros(removeLeadingZeros({
            sign,
            digits,
            fractionStart: fractionStart !== null && fractionStart !== void 0 ? fractionStart : digits.length
        }));
    }
}
export { Numeral as default };
