/**
 * @description on iOS when keyboard disappears WebView doesn't restore its previous position
 * @returns {void}
 */ export default function addIosWebViewPositionFix() {
    var _window_device;
    if (((_window_device = window.device) === null || _window_device === void 0 ? void 0 : _window_device.platform) !== 'iOS') {
        return;
    }
    let frameId;
    document.addEventListener('focusout', ()=>{
        if (frameId !== undefined) {
            cancelAnimationFrame(frameId);
        }
        frameId = requestAnimationFrame(()=>{
            const { body, activeElement } = document;
            frameId = undefined;
            // Do not intervene if a user switched focus to another focusable (input/textarea/button/etc.) element
            // as iOS will automatically scroll to that element
            if (!activeElement || activeElement === body) {
                window.scrollTo(body.scrollLeft, body.scrollTop);
            }
        });
    }, false);
}
