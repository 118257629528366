import getRelativeDifference from '@finst/core/src/scripts/utils/number/get-relative-difference';
import calculateAssetPosition from './calculate-asset-position';
export default function calculateAssetPositions(baseAssetSymbol, positions, update) {
    let hasChangedReferencePrice = false;
    let value = 0;
    let absoluteDailyPl = 0;
    let absoluteTotalPl = 0;
    let absolutePositionPl = 0;
    let totalSizePrice = 0;
    let totalSizeBreakEvenPrice = 0;
    const calculatedPositions = positions.map((position)=>{
        const assetValues = update[`${position.assetSymbol}~${baseAssetSymbol}`];
        if (position.assetSymbol === baseAssetSymbol) {
            position = calculateAssetPosition(position, 1, undefined);
        } else if (assetValues) {
            const { referencePrice } = assetValues;
            position = calculateAssetPosition(position, assetValues.lastPrice, assetValues.relativeDifference);
            if (referencePrice !== undefined) {
                // Request a snapshot when the reference price changes. This is needed to display a correct daily PL
                if (position.referencePrice !== undefined && position.referencePrice !== referencePrice) {
                    hasChangedReferencePrice = true;
                }
                position.referencePrice = referencePrice;
            }
        }
        const { price, breakEvenPrice } = position;
        // it's acceptable for us to lose a precision in this calculation
        const size = Number(position.size);
        value += position.value || 0;
        absoluteDailyPl += position.absoluteDailyPl || 0;
        absoluteTotalPl += position.absoluteTotalPl || 0;
        absolutePositionPl += position.absolutePositionPl || 0;
        totalSizePrice += price === undefined ? 0 : price * size;
        totalSizeBreakEvenPrice += breakEvenPrice === undefined ? 0 : breakEvenPrice * size;
        return position;
    });
    const absoluteUnrealisedPl = totalSizePrice - totalSizeBreakEvenPrice;
    return {
        positions: calculatedPositions,
        hasChangedReferencePrice,
        value,
        absoluteDailyPl,
        absoluteTotalPl,
        absolutePositionPl,
        absoluteUnrealisedPl,
        // the same logic as in the calculateAssetPosition()
        relativeUnrealisedPl: absoluteUnrealisedPl ? getRelativeDifference(totalSizePrice, totalSizeBreakEvenPrice) : 0,
        absoluteRealisedPl: absoluteTotalPl - absoluteUnrealisedPl,
        absoluteRealisedPositionPl: absolutePositionPl - absoluteUnrealisedPl
    };
}
