import { useCallback, useEffect, useState } from 'preact/compat';
const getCurrentHash = ()=>window.location.hash.slice(1);
export default function useLocationHash() {
    const [hash, setHash] = useState(getCurrentHash);
    const update = useCallback((hash)=>window.location.hash = hash && `#${hash}`, []);
    useEffect(()=>{
        const checkHash = ()=>{
            setHash(getCurrentHash());
        };
        window.addEventListener('hashchange', checkHash, false);
        // see useRouter() hook
        window.addEventListener('routerNavigate', checkHash, false);
        return ()=>{
            window.removeEventListener('hashchange', checkHash, false);
            window.removeEventListener('routerNavigate', checkHash, false);
        };
    }, []);
    return {
        hash,
        update
    };
}
