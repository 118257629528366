import add from './add';
import invertSign from './invert-sign';
import isInteger from './is-integer';
import removeLeadingZeros from './remove-leading-zeros';
import removeTrailingZeros from './remove-trailing-zeros';
export default function subtract(minuend, subtrahend) {
    if (isInteger(minuend, 0)) {
        return invertSign(subtrahend);
    }
    if (isInteger(subtrahend, 0)) {
        return minuend;
    }
    // Check if the signs differ
    if (minuend.sign !== subtrahend.sign) {
        return add(minuend, invertSign(subtrahend));
    }
    let minuendDigits = minuend.digits.slice(0);
    let subtrahendDigits = subtrahend.digits.slice(0);
    const fractionStart = Math.max(minuend.fractionStart, subtrahend.fractionStart);
    let { sign } = minuend;
    // prepend zeros to equalise minuend exponent
    for(let i = fractionStart - minuend.fractionStart; i > 0; i--){
        minuendDigits.unshift(0);
    }
    // prepend zeros to equalise subtrahend exponent
    for(let i = fractionStart - subtrahend.fractionStart; i > 0; i--){
        subtrahendDigits.unshift(0);
    }
    const minuendDigitsCount = minuendDigits.length;
    const subtrahendDigitsCount = subtrahendDigits.length;
    const maxDigitsCount = Math.max(minuendDigitsCount, subtrahendDigitsCount);
    const digits = [];
    let carry = 0;
    for(let i = 0; i < maxDigitsCount; i++){
        const minuendDigit = minuendDigits[i] || 0;
        const subtrahendDigit = subtrahendDigits[i] || 0;
        if (minuendDigit > subtrahendDigit) {
            break;
        } else if (minuendDigit < subtrahendDigit) {
            // swap the numbers to substract from the larger one
            [subtrahendDigits, minuendDigits] = [
                minuendDigits,
                subtrahendDigits
            ];
            sign = subtrahend.sign === '-' ? '+' : '-';
            break;
        }
    }
    for(let i = maxDigitsCount - 1; i >= 0; i--){
        const minuendDigit = (minuendDigits[i] || 0) - carry;
        const subtrahendDigit = subtrahendDigits[i] || 0;
        if (minuendDigit >= subtrahendDigit) {
            carry = 0;
            digits[i] = minuendDigit - subtrahendDigit;
        } else {
            carry = 1;
            digits[i] = 10 + minuendDigit - subtrahendDigit;
        }
    }
    return removeTrailingZeros(removeLeadingZeros({
        sign,
        digits,
        fractionStart
    }));
}
