import localLogger from '../../loggers/local-logger';
import parseApiErrorResponse from './parse-api-error-response';
const pendingRequests = new Map();
let globalHeaders = {};
export function setGlobalHeaders(headers) {
    globalHeaders = {
        ...headers
    };
}
export default async function request(options) {
    const { cache, body, params = {} } = options;
    const requestUrlInfo = new URL(options.url, location.href);
    Object.entries(params).forEach(([name, value])=>{
        if (value != null) {
            requestUrlInfo.searchParams.set(name, String(value));
        }
    });
    const requestUrl = requestUrlInfo.toString();
    let responseFromCache;
    if (cache) {
        try {
            var _response;
            const response = await cache.get({
                url: requestUrl
            });
            // Important: clone response to prevent "body in use" error
            responseFromCache = (_response = response) === null || _response === void 0 ? void 0 : _response.clone();
        } catch (error) {
            localLogger.error('ResourceCache#get() error:', {
                requestUrl,
                error
            });
        }
    }
    let pendingRequest = responseFromCache ? Promise.resolve(responseFromCache) : pendingRequests.get(requestUrl);
    if (!pendingRequest) {
        const headers = {
            ...globalHeaders,
            Accept: 'application/json, text/plain, */*'
        };
        const fetchMethod = options.method || 'GET';
        const fetchInit = {
            method: fetchMethod,
            mode: options.mode || 'cors',
            credentials: options.credentials || 'include'
        };
        if (body instanceof FormData) {
            fetchInit.body = body;
        } else if (body || fetchMethod === 'POST' || fetchMethod === 'PUT') {
            // Fix Tomcat bug: it requires 'Content-Type' for POST/PUT requests even with empty body
            headers['Content-Type'] = 'application/json;charset=UTF-8';
            fetchInit.body = JSON.stringify(body || {});
        }
        fetchInit.headers = {
            ...headers,
            ...options.headers
        };
        pendingRequest = fetch(requestUrl, fetchInit);
        // Prevent sending same parallel requests for the same resource.
        // This applies only to GET requests, because other types might modify BE state
        if (fetchMethod === 'GET') {
            // Pending request will get many consumers of the response (see response handling below in this file),
            // that's why response should be cloned to prevent an error during the reading of consumed body,
            // e.g. see iOS 10-11.x "Cannot consume a disturbed Response body ReadableStream" error
            //
            // IMPORTANT: add additional clone resolve only when it's needed and
            // not for every request (performance in WebKit)
            pendingRequest = pendingRequest.then((response)=>response.clone());
            pendingRequests.set(requestUrl, pendingRequest);
        }
    }
    try {
        const response = await pendingRequest;
        if (response.ok) {
            let responseToRead;
            let responseToCache;
            try {
                // Important: clone response to prevent "body in use" error
                responseToRead = response.clone();
                // If cache is not used, do not create unnecessary response clone (performance in WebKit)
                responseToCache = cache && response.clone();
            } catch  {
                // iOS 11.X doesn't allow to clone response – "Cannot clone a disturbed Response", see
                // https://bugs.webkit.org/show_bug.cgi?id=171552,
                // https://github.com/github/fetch/issues/504
                //
                // So if it happens, we return an origin response and nothing to cache, because
                // cache should work ONLY WITH CLONED Response instance
                responseToRead = response;
                responseToCache = undefined;
            }
            const responseBody = await responseToRead.json();
            if (cache && responseToCache && !responseFromCache) {
                try {
                    await cache.set({
                        url: requestUrl,
                        response: responseToCache
                    });
                } catch (error) {
                    localLogger.error('ResourceCache#set() error:', {
                        requestUrl,
                        error
                    });
                }
            }
            return responseBody.data;
        }
        let responseError;
        // server might send error details
        try {
            const responseBody = await response.json();
            responseError = parseApiErrorResponse(response, responseBody);
        } catch  {
            responseError = parseApiErrorResponse(response, undefined);
        }
        throw responseError;
    } finally{
        // remove finished pending request
        pendingRequests.delete(requestUrl);
    }
}
