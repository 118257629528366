import { setItem } from '@finst/core/src/scripts/utils/platform/native-storage';
import { localAccountsStoreKey } from '../../models/account';
import getLocalAccounts from './get-local-accounts';
export default async function saveLocalAccount(account) {
    const accounts = await getLocalAccounts();
    return setItem(localAccountsStoreKey, [
        account,
        ...accounts.filter((acc)=>acc.email !== account.email)
    ]);
}
