/**
 * - all values should be aligned with .css files
 * - it's not possible to use `exportTo` from postcss-custom-media, because it generates a file BEFORE Webpack needs it
 */ export const xsmallViewportMaxWidth = self.matchMedia('(max-width: 767px)');
export const mediumViewportMinWidth = self.matchMedia('(min-width: 1024px)');
export const xmediumViewportMinWidth = self.matchMedia('(min-width: 1280px)');
export const xxmediumViewportMinWidth = self.matchMedia('(min-width: 1440px)');
export const xLargeViewportMinWidth = self.matchMedia('(min-width: 1920px)');
export const fullScreenModal = self.matchMedia('(max-width: 480px)');
export const darkColorScheme = self.matchMedia('(prefers-color-scheme: dark)');
