import { AppError } from '@finst/core/src/scripts/models/app-error';
import getAllAssets from './get-all-assets';
export default async function getAssets(symbols) {
    const allAssets = await getAllAssets();
    const allAssetsDictionary = new Map(allAssets.map((asset)=>[
            asset.symbol,
            asset
        ]));
    const assets = symbols.reduce((assets, symbol)=>{
        const asset = allAssetsDictionary.get(symbol);
        if (asset) {
            assets.push(asset);
        }
        return assets;
    }, []);
    if (assets.length !== symbols.length) {
        throw new AppError({
            code: 'NotFound'
        });
    }
    return assets;
}
