export default function getMarketData(assetsData) {
    let totalMarketCapByRelativeDifference;
    let totalMarketCap;
    for(const key in assetsData){
        const { marketCap, relativeDifference } = assetsData[key];
        if (marketCap !== undefined && relativeDifference !== undefined) {
            totalMarketCap = (totalMarketCap || 0) + marketCap;
            totalMarketCapByRelativeDifference = (totalMarketCapByRelativeDifference || 0) + relativeDifference * marketCap;
        }
    }
    return {
        relativeDifference: totalMarketCapByRelativeDifference !== undefined && totalMarketCap !== undefined ? totalMarketCapByRelativeDifference / totalMarketCap : undefined
    };
}
