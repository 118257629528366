import { defaultLanguageCode } from './i18n';
export const localStoreThemeKey = 'theme';
export const localStoreLanguageKey = 'language';
export const localStoreDisplayNameKey = 'displayName';
export const defaultAppSettings = {
    language: defaultLanguageCode,
    theme: 'light',
    thousandSeparator: '.',
    decimalSeparator: ',',
    accountSummaryVisibility: 'visible',
    accountSummaryPlType: 'daily',
    displayName: ''
};
