import request from '@finst/core/src/scripts/services/api/request';
import getAssets from '../asset/get-assets';
export default async function getStakingAssetsInfo() {
    const items = await request({
        url: '/api/staking/assets'
    });
    const assets = await getAssets(items.map((item)=>item.assetSymbol));
    return items.map(({ assetSymbol, ...rest }, index)=>({
            ...rest,
            asset: assets[index]
        }));
}
