import removeTrailingZeros from './remove-trailing-zeros';
export default function round(state, roundingFractionDigits, roundingMode = 'halfUp') {
    let { fractionStart, digits } = state;
    const roundingDigitIndex = fractionStart + roundingFractionDigits;
    const roundingDigit = digits[roundingDigitIndex];
    if (roundingDigit === undefined) {
        return state;
    }
    // trim fraction from the end
    digits = digits.slice(0, roundingDigitIndex);
    fractionStart = Math.min(fractionStart, digits.length);
    if (roundingMode === 'halfUp' && roundingDigit >= 5) {
        for(let i = roundingDigitIndex - 1; i >= 0; i--){
            const digit = digits[i];
            if (digit === 9) {
                digits[i] = 0;
                if (i === 0) {
                    // increase integer part
                    digits.unshift(1);
                    fractionStart++;
                }
            } else {
                digits[i] = digit + 1;
                break;
            }
        }
    }
    return removeTrailingZeros({
        sign: state.sign,
        fractionStart,
        digits
    });
}
