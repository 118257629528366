import { _ as _define_property } from "@swc/helpers/_/_define_property";
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import unsubscribeAll from '@finst/core/src/scripts/utils/subscription/unsubscribe-all';
class OrdersBroker {
    on(callback) {
        this.subscriptions.add(callback);
        if (this.isOrdersSnapshotLoaded) {
            callback(this.orders);
        }
        return ()=>{
            this.subscriptions.delete(callback);
        };
    }
    close() {
        unsubscribeAll(this.unsubscribes);
        this.subscriptions.clear();
    }
    constructor(props){
        _define_property(this, "isOrdersSnapshotLoaded", false);
        _define_property(this, "orders", []);
        _define_property(this, "subscriptions", new Set());
        _define_property(this, "unsubscribes", void 0);
        _define_property(this, "onSnapshot", (orders)=>{
            this.isOrdersSnapshotLoaded = true;
            this.onUpdate(orders);
        });
        _define_property(this, "onUpdate", (updates)=>{
            const updatedOrders = new Map(Object.entries(updates));
            let orders = this.orders.map((storedOrder)=>{
                const updatedOrder = updatedOrders.get(storedOrder.id);
                if (updatedOrder) {
                    // Delete handled order
                    updatedOrders.delete(updatedOrder.id);
                    // Check if the updated order is newer than the stored one
                    if (new Date(updatedOrder.modifiedAt).getTime() > new Date(storedOrder.modifiedAt).getTime()) {
                        return updatedOrder;
                    }
                }
                return storedOrder;
            });
            // All unhandled orders are new
            orders = [
                ...Array.from(updatedOrders.values()),
                ...orders
            ];
            this.orders = orders;
            this.subscriptions.forEach((callback)=>callback(orders));
        });
        const { messageBroker } = props;
        this.unsubscribes = [
            messageBroker.on({
                action: 'ordersData',
                onSuccess: this.onSnapshot,
                onError: localLogger.error
            }),
            messageBroker.on({
                action: 'ordersUpdate',
                onSuccess: this.onUpdate,
                onError: localLogger.error
            }),
            // request a snapshot again when a new connection established (e.g. after reconnect)
            messageBroker.onConnect(()=>messageBroker.send({
                    action: 'ordersRequest',
                    data: {},
                    onError: localLogger.error
                }))
        ];
    }
}
export { OrdersBroker as default };
