import request from '../../services/api/request';
import isAppError from '../../services/app-error/is-app-error';
import localLogger from '../local-logger';
import getStackInfo from './get-stack-info';
let loggerConfig = {
    serviceName: '',
    serviceVersion: ''
};
const remoteLogger = {
    configure (config) {
        loggerConfig = {
            ...config
        };
    },
    error (error) {
        var _nativeError;
        if (!Globals.PROD_BUILD) {
            return;
        }
        const nativeError = isAppError(error) ? error.cause : error;
        const stack = ((_nativeError = nativeError) === null || _nativeError === void 0 ? void 0 : _nativeError.stack) || '';
        const message = [
            isAppError(error) ? JSON.stringify(error) : '',
            nativeError && !stack.includes(nativeError.message) ? nativeError.message : '',
            stack
        ].join('\n\n').trim().slice(0, 2000);
        const errorInfo = {
            ...loggerConfig,
            ...stack && getStackInfo(stack),
            message
        };
        request({
            url: '/api/error-reporting/errors',
            method: 'POST',
            body: [
                errorInfo
            ]
        }).catch(localLogger.error.bind(null, 'Remote error reporting failed'));
    }
};
export default remoteLogger;
