import { _ as _define_property } from "@swc/helpers/_/_define_property";
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import unsubscribeAll from '@finst/core/src/scripts/utils/subscription/unsubscribe-all';
class UpvoteBroker {
    add(assetSymbols) {
        this.messageBroker.send({
            action: 'upvoteAdd',
            data: {
                assetSymbols
            },
            onError: localLogger.error
        });
    }
    remove(assetSymbols) {
        this.messageBroker.send({
            action: 'upvoteRemove',
            data: {
                assetSymbols
            },
            onError: localLogger.error
        });
    }
    on(callback) {
        this.subscriptions.add(callback);
        if (this.upvoteList) {
            callback(this.upvoteList);
        }
        return ()=>{
            this.subscriptions.delete(callback);
        };
    }
    close() {
        unsubscribeAll(this.unsubscribes);
        this.subscriptions.clear();
    }
    constructor(props){
        _define_property(this, "messageBroker", void 0);
        _define_property(this, "upvoteList", void 0);
        _define_property(this, "subscriptions", new Set());
        _define_property(this, "unsubscribes", void 0);
        _define_property(this, "onSnapshot", (upvoteList)=>{
            this.upvoteList = upvoteList;
            this.subscriptions.forEach((callback)=>callback(upvoteList));
        });
        const { messageBroker } = props;
        this.messageBroker = messageBroker;
        this.unsubscribes = [
            messageBroker.on({
                action: 'upvoteListData',
                onSuccess: this.onSnapshot,
                onError: localLogger.error
            }),
            // request a snapshot again when a new connection established (e.g. after reconnect)
            messageBroker.onConnect(()=>messageBroker.send({
                    action: 'upvoteListRequest',
                    data: {},
                    onError: localLogger.error
                }))
        ];
    }
}
export { UpvoteBroker as default };
