import request from '@finst/core/src/scripts/services/api/request';
let lastResult;
export default async function getAllAssets() {
    if (!lastResult) {
        // keep ALL assets in memory on FE
        // TODO: consider adding BE endpoint to load assets by query params instead of loading ALL
        lastResult = await request({
            url: '/api/assets/all'
        });
    }
    return lastResult;
}
