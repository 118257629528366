export default function removeLeadingZeros(state) {
    const { digits, fractionStart } = state;
    let leadingZerosCount = 0;
    for(let i = 0; i < fractionStart - 1; i++){
        if (digits[i] === 0) {
            leadingZerosCount++;
        } else {
            break;
        }
    }
    return {
        sign: state.sign,
        digits: digits.slice(leadingZerosCount),
        fractionStart: fractionStart - leadingZerosCount
    };
}
